<template>
	<div class="box box--success">
		<div class="media">
			<div class="meda__img h-space--right">
				<svg-icon class="icon--green" width="24" height="24" name="check" />
			</div>
			<div class="box__message media__bd" v-if="html" v-html="html"></div>
			<slot class="box__message media__bd"></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'box-success',
	props: {
		html: {
			type: String,
			default: '',
		},
	},
}
</script>
